import { isKinApp } from '@/helpers/kinn';

import { getStore} from "@/store";
import { ACTION_INIT_WISHLIST, MODULE_NAME } from "@/store/dashboard/wishlist/constants";

import DetailsModal from "@/custom-elements/details-modal";
import PredictiveSearch from "@/custom-elements/predictive-search";

import { EVENT_MINICART_PRODUCT_ADD } from "@/store/events";

// @TODO check impact of this one. Import only where it is needed
function initAutoCloseAccordions() {
    const accordions = document.querySelectorAll('[data-close-accordions]');
    if (!accordions) {
        return;
    }

    if (!accordions?.length) {
        return;
    }

    accordions?.forEach((accordion, index) => {
        const accordions_to_close = Array?.from(accordions)?.filter((_acc, _index) => {
            return ![
                _acc?.dataset?.closeAccordions === accordion?.dataset?.closeAccordions,
                _index !== index
            ]?.includes(false);
        }) || [];

        if (!accordions_to_close) {
            return;
        }

        if (!accordions_to_close?.length) {
            return;
        }

        accordion?.addEventListener('toggle', () => {
            if (!accordion?.open) {
                return;
            }

            accordions_to_close?.forEach((accordion_to_close) => {
                accordion_to_close.open = false;
            });
        });
    });
}

// @TODO move it elsewhere. Possibly -> header.js
function initHeights() {
    const header = document?.querySelector('[data-site-header]');
    if (header) {
        const height = header?.getBoundingClientRect()?.height || 0;
        if (height) {
            document?.documentElement?.style?.setProperty('--header-height', `${ height - 2 }px`);
        }
    }

    const announcement = document?.querySelector('[data-announcement-bar]');
    if (announcement) {
        const height = announcement?.getBoundingClientRect()?.height || 0;
        if (height) {
            document?.documentElement?.style?.setProperty('--announcement-height', `${ height }px`);
        }
    }

    const promobar = document?.querySelector('[data-promobar]');
    if (promobar) {
        const height = promobar?.getBoundingClientRect()?.height || 0;
        if (height) {
            document?.documentElement?.style?.setProperty('--promobar-height', `${ height }px`);
        }
    }

    const secondaryHeader = document?.querySelector('#shopify-section-header-secondary');
    if (secondaryHeader) {
        const height = secondaryHeader?.getBoundingClientRect()?.height || 0;
        if (height) {
            document?.documentElement?.style?.setProperty('--secondary-header-height', `${ height }px`);
        }
    }
}

export function checkForApp() {
    if (!isKinApp()) {
        return;
    }

    document?.body?.classList?.add('is-app--kinn');
}


// @TODO check what it is for
function makePostScriptInputRequired() {
    const input = document.getElementById('ps__phone-subscribe');
    if (!input) {
        return;
    }

    input.required = true;
}

function initProductSelectBoxes() {
    document.addEventListener('change', (event) => {
        if (!event?.target?.classList?.contains('product-select-box')) {
            return;
        }

        let selectedValue = event?.target?.value;
        let controlKitString = '';

        Array.from(event.target.parentElement.querySelectorAll('[data-product-select] > *'))
            .forEach((element) => {
                element.classList.remove('selected');
            });

        if (event.target.parentElement.querySelectorAll('.product-select-box').length > 1) {
            Array.from(event.target.parentElement.querySelectorAll('.product-select-box'))
                .forEach((element) => {
                    controlKitString = `${controlKitString + element.value  } / `;
                });
            selectedValue = controlKitString.slice(0, -3);
        }

        Array.from(document.querySelectorAll(`[data-product-select] [data-variant-value='${selectedValue}']`))
            .forEach((element) => {
                element.classList.add('selected');
            });

        document.querySelector('[data-product-select]').value = selectedValue;
        document.querySelector('[data-product-select]').dispatchEvent(new Event('change'));
    });
}

function initAddToCartForm() {
    document.addEventListener('click', (event) => {
        if (!event?.target?.classList?.contains('addCart-form')) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();

        const variantId = event?.target?.closest('.product-item')?.querySelector('.selected')?.getAttribute('value');

        if (!variantId) {
            return;
        }

        document.dispatchEvent(new CustomEvent(EVENT_MINICART_PRODUCT_ADD, {
            detail: {
                items: [
                    {
                        quantity: 1,
                        id: variantId
                    }
                ]
            }
        }));
    });
}

window.addEventListener('DOMContentLoaded', () => {
    window?.customElements?.define('details-modal', DetailsModal);
    window?.customElements?.define('predictive-search', PredictiveSearch);

    checkForApp();
    makePostScriptInputRequired();

    // @TODO check if it is needed
    initAutoCloseAccordions();

    /* MM */
    /* Set mm height */

    initHeights();
    window.addEventListener('resize', initHeights);

    // @TODO check if it is needed
    initProductSelectBoxes();

    // @TODO check if we need it
    initAddToCartForm();

    getStore().dispatch(`${MODULE_NAME}/${ACTION_INIT_WISHLIST}`, null, {
        root: true
    });
});
